exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/AboutPage.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/ContactPage.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-default-page-tsx": () => import("./../../../src/templates/DefaultPage.tsx" /* webpackChunkName: "component---src-templates-default-page-tsx" */),
  "component---src-templates-news-archive-tsx": () => import("./../../../src/templates/NewsArchive.tsx" /* webpackChunkName: "component---src-templates-news-archive-tsx" */),
  "component---src-templates-news-single-tsx": () => import("./../../../src/templates/NewsSingle.tsx" /* webpackChunkName: "component---src-templates-news-single-tsx" */),
  "component---src-templates-partners-archive-tsx": () => import("./../../../src/templates/PartnersArchive.tsx" /* webpackChunkName: "component---src-templates-partners-archive-tsx" */),
  "component---src-templates-service-single-tsx": () => import("./../../../src/templates/ServiceSingle.tsx" /* webpackChunkName: "component---src-templates-service-single-tsx" */),
  "slice---src-components-footer-footer-tsx": () => import("./../../../src/components/Footer/Footer.tsx" /* webpackChunkName: "slice---src-components-footer-footer-tsx" */),
  "slice---src-components-header-header-tsx": () => import("./../../../src/components/Header/Header.tsx" /* webpackChunkName: "slice---src-components-header-header-tsx" */),
  "slice---src-components-pages-default-page-page-content-tsx": () => import("./../../../src/components/Pages/DefaultPage/PageContent.tsx" /* webpackChunkName: "slice---src-components-pages-default-page-page-content-tsx" */)
}

